<template>
    <div v-if="replies.length">
        <hr class="hr" />
        <p class="profileBox__title2">
            Чат с куратором
        </p>
        <div class="curatorChat">
            <div
                v-for="(reply, index) in replies"
                :key="reply._id"
                :class="
                    reply.support_answer
                        ? 'curatorChatItem curatorChatItem--left'
                        : 'curatorChatItem'
                "
            >
                <div class="curatorChatItem__text text2" style="word-break: break-word">
                    <span
                        v-if="lesson.lesson.lesson.type === 'algorithm' && index === 0"
                        v-html="
                            reply.text
                                .replace(/\/files\/main/g, $apiUrl + 'files/main')
                                .replace(/a href/g, 'a target=\'_blank\' href')
                        "
                    ></span>
                    <span v-else v-html="reply.text"></span>
                    <div
                        v-if="reply.attachments.length"
                        class="curatorChatItem__attachments"
                    >
                        <div
                            v-for="(attachment, index) in reply.attachments"
                            :key="attachment._id"
                            class="curatorChatItem__attachment"
                        >
                            <a
                                :href="
                                    reply.support_answer
                                        ? $oldClientTestUrl + attachment.url.substring(1)
                                        : $apiUrl + attachment.url.substring(1)
                                "
                                target="_blank"
                                class="h5"
                            >
                                <span
                                    :class="
                                        `boxText boxText${
                                            attachment.url
                                                .toLowerCase()
                                                .includes('jpeg') ||
                                            attachment.url
                                                .toLowerCase()
                                                .includes('jpg') ||
                                            attachment.url
                                                .toLowerCase()
                                                .includes('png') ||
                                            attachment.url
                                                .toLowerCase()
                                                .includes('gif') ||
                                            attachment.url.toLowerCase().includes('svg')
                                                ? '--purple'
                                                : attachment.url
                                                      .toLowerCase()
                                                      .includes('pdf') ||
                                                  attachment.url
                                                      .toLowerCase()
                                                      .includes('txt')
                                                ? '--red'
                                                : attachment.url
                                                      .toLowerCase()
                                                      .includes('xls') ||
                                                  attachment.url
                                                      .toLowerCase()
                                                      .includes('xlsx') ||
                                                  attachment.url
                                                      .toLowerCase()
                                                      .includes('xlsm') ||
                                                  attachment.url
                                                      .toLowerCase()
                                                      .includes('ods')
                                                ? '--green'
                                                : '--blue'
                                        }`
                                    "
                                >
                                    {{
                                        attachment.url.split(".")[
                                            attachment.url.split(".").length - 1
                                        ]
                                    }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="
                (!lesson.isGraduated && lesson.lesson.lesson.type !== 'algorithm' ||
                (!lesson.isGraduatedByDatePlus90Days && lesson.lesson.lesson.type === 'algorithm')) &&
                    !lesson.resetProgressByClient &&
                    ((lesson.lesson.lesson.type === 'algorithm' &&
                        lesson.isAccessToCheckAlgorithm) ||
                        (lesson.lesson.lesson.type !== 'algorithm' &&
                            lesson.homework &&
                            lesson.homework.status !== 'approved'))
            "
            class="curatorChatAnswer"
        >
            <textarea
                class="curatorChatAnswer__textarea"
                rows="7"
                placeholder="Введите ваше сообщение"
                v-model="responseText"
            ></textarea>
            <InputZone
                class="curatorChatAnswer__dropzone"
                id="algorithmResponseFileDropzone"
                :fileType="allowedFiles === 'images' ? 'image/*' : ''"
                :files="responseFiles"
                :maxFiles="5"
                :maxSize="5"
                :isError="isFileNotValid"
                errorText="Вы не можете прикрепить файл этого формата, попробуйте прикрепить изображение"
                :onFilesUpdate="(e) => updateFiles(e)"
            />
            <div class="curatorChatAnswer__footer">
                <Button
                    class="curatorChatAnswer__submit"
                    title="Отправить"
                    :onClick="() => sendResponse()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import InputZone from "@/views/components/InputZone/InputZone";

export default {
    name: "HomeworkReplies",

    components: {
        Button,
        InputZone,
    },

    async beforeMount() {
        await this.updateLessonStore();
    },

    watch: {
        "$route.params.lesson_id": async function() {
            await this.updateLessonStore();

            this.responseFiles = [];
            this.responseText = "";
        },
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },

        replies: function() {
            return (
                (this.lesson && this.lesson.homework && this.lesson.homework.replies) ||
                []
            );
        },
    },

    data() {
        return {
            responseFiles: [],
            responseText: "",
            isFileNotValid: false,
        };
    },

    props: {
        allowedFiles: String,
    },

    methods: {
        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async sendResponse() {
            let filesValid = true;

            if (this.allowedFiles === "images") {
                filesValid = false;

                const filesFormats = ["jpeg", "jpg", "png", "svg"];

                if (this.responseFiles && this.responseFiles.length) {
                    const isValidFiles = this.responseFiles.map((item) =>
                        filesFormats.includes(item.filename.split(".")[1].toLowerCase())
                    );

                    filesValid = isValidFiles.includes(true) && !isValidFiles.includes(false);

                    if (isValidFiles.includes(true)) {
                        this.isFileNotValid = false;
                    }

                    if (isValidFiles.includes(false)) {
                        this.isFileNotValid = true;
                    }
                } else {
                    filesValid = true;
                }
            }

            // prettier-ignore
            if (this.responseText) {
                if (filesValid) {
                    const getReplyResponse = await this.axios.post(
                        "/programs/" +
                        this.$route.params.slug +
                        "/tasks/" +
                        this.lesson.homework.homework_id +
                        "/answer",
                        {
                            text: this.responseText,
                            files: this.responseFiles,
                        }
                    );

                    if (
                        getReplyResponse &&
                        getReplyResponse.data &&
                        getReplyResponse.data.result
                    ) {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "success",
                                text: "Ваше сообщение было успешно отправлено.",
                            },
                        });

                        await this.updateLessonStore();

                        this.responseText = "";
                        this.responseFiles = [];
                    } else {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text:
                                    (getReplyResponse &&
                                        getReplyResponse.data &&
                                        getReplyResponse.data.error) ||
                                    "Ошибка при отправке сообщения.",
                            },
                        });
                    }
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Неправильный формат файла.",
                        },
                    });
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Поле с сообщением пустое",
                    },
                });
            }
        },

        async updateFiles(passedFiles) {
            this.responseFiles = passedFiles;
        },
    },
};
</script>

<style scoped>
@import "HomeworkReplies.scss";
</style>
