var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.replies.length)?_c('div',[_c('hr',{staticClass:"hr"}),_c('p',{staticClass:"profileBox__title2"},[_vm._v(" Чат с куратором ")]),_c('div',{staticClass:"curatorChat"},_vm._l((_vm.replies),function(reply,index){return _c('div',{key:reply._id,class:reply.support_answer
                    ? 'curatorChatItem curatorChatItem--left'
                    : 'curatorChatItem'},[_c('div',{staticClass:"curatorChatItem__text text2",staticStyle:{"word-break":"break-word"}},[(_vm.lesson.lesson.lesson.type === 'algorithm' && index === 0)?_c('span',{domProps:{"innerHTML":_vm._s(
                        reply.text
                            .replace(/\/files\/main/g, _vm.$apiUrl + 'files/main')
                            .replace(/a href/g, 'a target=\'_blank\' href')
                    )}}):_c('span',{domProps:{"innerHTML":_vm._s(reply.text)}}),(reply.attachments.length)?_c('div',{staticClass:"curatorChatItem__attachments"},_vm._l((reply.attachments),function(attachment,index){return _c('div',{key:attachment._id,staticClass:"curatorChatItem__attachment"},[_c('a',{staticClass:"h5",attrs:{"href":reply.support_answer
                                    ? _vm.$oldClientTestUrl + attachment.url.substring(1)
                                    : _vm.$apiUrl + attachment.url.substring(1),"target":"_blank"}},[_c('span',{class:("boxText boxText" + (attachment.url
                                            .toLowerCase()
                                            .includes('jpeg') ||
                                        attachment.url
                                            .toLowerCase()
                                            .includes('jpg') ||
                                        attachment.url
                                            .toLowerCase()
                                            .includes('png') ||
                                        attachment.url
                                            .toLowerCase()
                                            .includes('gif') ||
                                        attachment.url.toLowerCase().includes('svg')
                                            ? '--purple'
                                            : attachment.url
                                                  .toLowerCase()
                                                  .includes('pdf') ||
                                              attachment.url
                                                  .toLowerCase()
                                                  .includes('txt')
                                            ? '--red'
                                            : attachment.url
                                                  .toLowerCase()
                                                  .includes('xls') ||
                                              attachment.url
                                                  .toLowerCase()
                                                  .includes('xlsx') ||
                                              attachment.url
                                                  .toLowerCase()
                                                  .includes('xlsm') ||
                                              attachment.url
                                                  .toLowerCase()
                                                  .includes('ods')
                                            ? '--green'
                                            : '--blue'))},[_vm._v(" "+_vm._s(attachment.url.split(".")[ attachment.url.split(".").length - 1 ])+" ")])])])}),0):_vm._e()])])}),0),(
            (!_vm.lesson.isGraduated && _vm.lesson.lesson.lesson.type !== 'algorithm' ||
            (!_vm.lesson.isGraduatedByDatePlus90Days && _vm.lesson.lesson.lesson.type === 'algorithm')) &&
                !_vm.lesson.resetProgressByClient &&
                ((_vm.lesson.lesson.lesson.type === 'algorithm' &&
                    _vm.lesson.isAccessToCheckAlgorithm) ||
                    (_vm.lesson.lesson.lesson.type !== 'algorithm' &&
                        _vm.lesson.homework &&
                        _vm.lesson.homework.status !== 'approved'))
        )?_c('div',{staticClass:"curatorChatAnswer"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.responseText),expression:"responseText"}],staticClass:"curatorChatAnswer__textarea",attrs:{"rows":"7","placeholder":"Введите ваше сообщение"},domProps:{"value":(_vm.responseText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.responseText=$event.target.value}}}),_c('InputZone',{staticClass:"curatorChatAnswer__dropzone",attrs:{"id":"algorithmResponseFileDropzone","fileType":_vm.allowedFiles === 'images' ? 'image/*' : '',"files":_vm.responseFiles,"maxFiles":5,"maxSize":5,"isError":_vm.isFileNotValid,"errorText":"Вы не можете прикрепить файл этого формата, попробуйте прикрепить изображение","onFilesUpdate":function (e) { return _vm.updateFiles(e); }}}),_c('div',{staticClass:"curatorChatAnswer__footer"},[_c('Button',{staticClass:"curatorChatAnswer__submit",attrs:{"title":"Отправить","onClick":function () { return _vm.sendResponse(); }}})],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }